import React, { useEffect, useState } from 'react'
import { useGlobalContext } from "../../lib/storage";
import { Row, Col, Avatar } from "antd"
import { useHistory } from "react-router-dom";

const MyApps = () => {
  const { globalStore, setGlobalStore } = useGlobalContext();
  let headerColor = globalStore.userData.CW360_V2_UI.appTheme.primaryColor;
  const menuList = globalStore.sideMenuData;
  const [menuData, setMenuData] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const history = useHistory();

  useEffect(() => {
    // getCwLogos();
    // getFaviouritesMenus();
    if (menuList) {
      try {
        const sortMenu = (menu) => {
          menu.sort((a, b) => {
            // Compare appSeq first
            const appSeqComparison = (a.appSeq != null ? parseInt(a.appSeq) : Infinity) - (b.appSeq != null ? parseInt(b.appSeq) : Infinity);

            // If appSeq values are equal, compare seqno
            if (appSeqComparison === 0) {
              return (a.seqno != null ? parseInt(a.seqno) : Infinity) - (b.seqno != null ? parseInt(b.seqno) : Infinity);
            }

            // If appSeq values are not equal, return the result of appSeq comparison
            return appSeqComparison;
          });

          // Recursively sort children if they exist
          menu.forEach((item) => {
            if (item.children && item.children.length > 0) {
              sortMenu(item.children);
            }
          });
        };

        // Sort the menuList
        sortMenu(menuList);

        // console.log(menuList);
        setMenuData([...menuList]);
      } catch (error) {
        console.error("Failed to set menu data: ", JSON.stringify(error, null, 2));
      }
    }
    // setBunitId(globalStore.userData.bunit_id);
    // setRoleId(globalStore.userData.role_id);
  }, []);

  const handleListClick = (menuKey, menu) => {
    // setShowExpandedMenu(false)
    // console.log('Clicked Item Key:', menuKey);
    // console.log('Clicked Menu:', menu);
    menuData.map((menu, index) => {
      if (menu.key === menuKey) {
        localStorage.setItem("selectecMianMenuItem", JSON.stringify(menuKey))
        localStorage.setItem("subMenuData", JSON.stringify(menu.children))
        // setSelectedMainMenuItem(menu.children)
      }
    })
    navigateToFirstChild({ type: menu.children[0].type, id: menu.children[0].id, key: menu.children[0].key, children: menu.children[0].children })
  }

  const navigateToFirstChild = (data) => {
    if (data.children === undefined || data.children === null) {
      localStorage.removeItem('lowerSubMenu')
      localStorage.setItem("subMenuItem", JSON.stringify(data.key))
      // setMenuToggle(true)
      // setFullMenuToggle(true)
      if (data.type === "Report") {
        history.push(`/reports/report/${data.id}`);
      } else if (data.type === "Dashboard") {
        localStorage.setItem("windowType", "Dashboard")
        history.push(`/analytics/dashboard/${data.id}`);
      } else if (data.type === "Generic") {
        history.push(`/window/list/${data.id}`);
      } else if (data.type === "Custom") {
        history.push(`/others/window/${data.id}`);
      }
    }
    else {
      // setMenuToggle(false)
      localStorage.setItem("subMenuItem", JSON.stringify(data.key))
      localStorage.setItem("lowerSubMenu", JSON.stringify(data.children))
      localStorage.setItem("lowerSubMenuItem", JSON.stringify(data.children[0].key))
      if (data.children[0].type === "Report") {
        localStorage.setItem("windowType", "Report")
        history.push(`/reports/report/${data.children[0].id}`);
      } else if (data.children[0].type === "Dashboard") {
        localStorage.setItem("windowType", "Dashboard")
        history.push(`/analytics/dashboard/${data.children[0].id}`);
      } else if (data.children[0].type === "Generic") {
        history.push(`/window/list/${data.children[0].id}`);
      } else if (data.children[0].type === "Custom") {
        history.push(`/others/window/${data.children[0].id}`);
      }

    }

  }

  return (
    <Row gutter={[16, 16]}>
      {menuData && menuData.map((item, index) => (
        <Col key={index} span={4} style={{ height: "10em", borderRadius: "1em" }}>
          <div
            onMouseEnter={() => setHoveredIndex(index)}
            onMouseLeave={() => setHoveredIndex(null)}
            onClick={() => handleListClick(item.key, item)}
            style={{
              display: "flex", // Use flexbox to center contents
              flexDirection: "column",
              alignItems: "center", // Center vertically
              justifyContent: "center", // Center horizontally
              borderRadius: "1em",
              backgroundColor: hoveredIndex === index ? "#DCDFEE" : "white",
              height: "10em",
              padding: "0.7em",
              transition: "background-color 0.3s, transform 0.3s",
              transform: hoveredIndex === index ? "scale(1.01)" : "scale(1)",
              cursor: "pointer"
            }}
          >
            {item.icon ? <span
              className={item.icon}
              style={{
                color: headerColor,
                fontSize: "2em",
                padding: "0.5em",
                paddingTop: "1em",
                borderRadius: "8px"
              }}
            ></span> :

              <Avatar
                size={45}
                style={{
                  border: "2.5px solid",
                  borderColor:headerColor,
                  fontWeight: 600,
                  fontSize: "12px",
                  background: "transparent",
                  color: headerColor,
                  display: "flex", // Use Flexbox
                  alignItems: "center", // Center vertically
                  justifyContent: "center", // Center horizontally
                }}
              >
                {item.title.substring(0, 2).toUpperCase()}
              </Avatar>
            }

            <p style={{ color: headerColor, fontWeight: 600, fontFamily: "Inter", fontSize: "18px", padding: "0.5em", paddingTop: "0" }}> {item.title}</p>

          </div>
        </Col>
      ))}
    </Row>
  )
}
export default MyApps;
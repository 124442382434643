import React, { useState, useEffect, useReducer, useRef } from "react";
import { Spin, Tabs } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router";
import { getWindowDefinition } from "../../services/generic";
import { WindowContext, storeReducer, useGlobalContext } from "../../lib/storage";
import ListWindow from "../listWindow";
import RecordWindow from "../recordWindow";
import ThemeJson from "../../constants/UIServer.json";

import "antd/dist/antd.css";

const Window = () => {
  const { TabPane } = Tabs;
  const { globalStore, setGlobalStore } = useGlobalContext();
  const [loading, setLoading] = useState(false);
  const [windowStore, setWindowStore] = useReducer(storeReducer, { windowDefinition: {} });
  const { windowId, recordId } = useParams();
  const [flag,setFlag] = useState(true)
  const [headerTabs, setHeaderTabs] = useState([]);
  const [selectedTabData, setSelectedTabData] = useState(null);
  const [selectedTabID, setSelectedTabID] = useState(null);
  let themeColor = globalStore.userData.CW360_V2_UI.appTheme.themePrimaryColor;
  const Themes = ThemeJson;
  const history = useHistory();

  const windowLocationPath = useRef(history.location.pathname);
  const { userPreferences, windowTabs } = globalStore;

  const [windowRefresh, setWindowRefresh] = useState(new Date());

  useEffect(() => {
    if (userPreferences.enableMultiTab !== "Y") {
      setWindowRefresh(new Date());
    }
  }, [windowId]);

  useEffect(() => {
    const definition = localStorage.getItem("windowDefinitions");
    if (definition) {
      let definitionParsed = JSON.parse(definition);
      for (let index = 0; index < definitionParsed.length; index++) {
        if (definitionParsed[index].ad_window_id === windowStore.windowDefinition.ad_window_id) {
          definitionParsed[index] = windowStore.windowDefinition;
        }
      }
      localStorage.setItem("windowDefinitions", JSON.stringify(definitionParsed));
    }
  }, [windowStore]);

  useEffect(() => {
    if (windowId) {
      (async () => {
        try {
          setLoading(true);
          const windowDefinitionData = await getWindowDefinition(windowId);
          windowDefinitionData.tabs.forEach((tab) => {
            if (tab.child_tab_ids) {
              const childTabIds = tab.child_tab_ids.split(",");
              childTabIds.forEach((childTabId) => {
                const tabIndex = windowDefinitionData.tabs.findIndex((inTab) => inTab.ad_tab_id === childTabId);
                if (tabIndex >= 0) {
                  windowDefinitionData.tabs[tabIndex].parent_tab_id = tab.ad_tab_id;
                }
              });
            }
          });
          setWindowStore({ windowDefinition: windowDefinitionData });
        } catch (error) {
          console.error("Error obtaining window definition: ", error);
        } finally {
          setLoading(false);
        }
      })();
    }
  }, [windowRefresh]);

  useEffect(() => {
    if (userPreferences.enableMultiTab === "Y" && history.location.pathname.search(`/window/${windowId}`) >= 0) {
      if (windowTabs.length > 0) {
        const localWindowTabs = [...windowTabs];
        const tabIdx = localWindowTabs.findIndex((tab) => tab.url === windowLocationPath.current);
        if (tabIdx >= 0) {
          localWindowTabs[tabIdx].url = history.location.pathname;
          setGlobalStore({ windowTabs: [...localWindowTabs] });
        }
      }
      windowLocationPath.current = history.location.pathname;
    }
  }, [history.location.pathname]);

  const { windowDefinition } = windowStore;

  useEffect(() => {
    if (windowDefinition.tabs) {
      const levelZeroTabs = windowDefinition.tabs.filter((tab) => tab.tablevel === "0");
      levelZeroTabs.forEach((headerTab) => {
        headerTab.fields.sort((a, b) => {
          const x = a.grid_seqno !== null ? parseInt(a.grid_seqno) : a.grid_seqno;
          const y = b.grid_seqno !== null ? parseInt(b.grid_seqno) : b.grid_seqno;
          return (x != null ? x : Infinity) - (y != null ? y : Infinity);
        });
      });
      setHeaderTabs(levelZeroTabs);
      if (levelZeroTabs.length > 1) {
        // Set the first tab as selected initially
        setSelectedTabData(levelZeroTabs[0]);
        setSelectedTabID(levelZeroTabs[0].ad_tab_id);
      }else{
        setWindowStore({ activeTabData: null });
        setHeaderTabs(null);
      }
    }
  }, [windowDefinition,]);

  const handleTabChange = (activeKey) => {
    console.log("Tab changed to:", activeKey); // Debug: check activeKey
    const activeTab = headerTabs.find(tab => tab.ad_tab_id === activeKey);
    history.push(`/window/list/${activeKey}/${windowId}`)
    if (activeTab) {
      setWindowStore({ activeTabData: activeTab });
      setSelectedTabData(activeTab);
      setSelectedTabID(activeKey);
      // setFlag(!flag)
    }
  };

  const setDynamicInkBarColor = (color) => {
    // Check if the style element already exists
    let styleElement = document.getElementById('dynamic-ink-bar-style');
    
    // If not, create a new style element
    if (!styleElement) {
      styleElement = document.createElement('style');
      styleElement.id = 'dynamic-ink-bar-style';
      document.head.appendChild(styleElement);
    }
  
    // Set the dynamic CSS rule for .ant-tabs-ink-bar
    styleElement.innerHTML = `
      .multiTab .ant-tabs-ink-bar {
        background: ${color} !important;
      }
    `;
  };
  
  const dynamicColor = themeColor; // This color value is passed dynamically
  setDynamicInkBarColor(dynamicColor);
  
  return (
    <WindowContext.Provider value={{ windowStore, setWindowStore }}>
      <Spin indicator={<LoadingOutlined className="spinLoader" style={Themes.contentWindow.mainCard} spin />} spinning={loading}>
        {headerTabs?.length > 1 ? (
          <Tabs tabBarStyle={{borderBottom:"1px solid #E7E7E7",height:"22px"}} className="multiTab" defaultActiveKey={selectedTabID} onChange={handleTabChange} >
            {headerTabs.map((data) =>
              data.isactive === "Y" ? (
                <TabPane style={{padding:"4px 0"}} tab={<span style={{color:selectedTabID === data.ad_tab_id ?"#":"#192228"}} >{data.name}</span>}  key={data.ad_tab_id}>
                  {loading ? null : recordId && windowId ? (
                    <RecordWindow  />
                  ) : (
                    <ListWindow flag={flag}/>
                  )}
                </TabPane>
              ) : null
            )}
          </Tabs>
        ) : (
          loading ? null : recordId && windowId ? (
            <RecordWindow  />
          ) : (
            <ListWindow  />
          )
        )}
      </Spin>
    </WindowContext.Provider>
  );
};

export default Window;

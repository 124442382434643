import React, { useState, useEffect } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useHistory } from "react-router-dom";
import { Menu, Layout, Card, Avatar, message, Row, Col, Tooltip, Button,List,Popover } from "antd";
import { LeftOutlined,RightOutlined,MinusCircleOutlined } from '@ant-design/icons';
import ThemeJson from "../../constants/UIServer.json"
import sideIcon from "../../assets/images/sideNavIcon.svg"
import { useGlobalContext } from "../../lib/storage";
import iconSide from "../../assets/images/hoverLine.svg"
import backLeft from "../../assets/images/menuHide.svg"
import "../../styles/app.css";
import "../../styles/antd.css";
import "./menu.css";

const { Sider } = Layout;

const SideNav = (props) => {
  // const textInput = React.useRef(null);
  const selectedApp = localStorage.getItem("appName");
  const { globalStore, setGlobalStore } = useGlobalContext();
  const Themes = ThemeJson;
  const { sideMenuData: menuList, windowTabs, userPreferences } = globalStore;
  const [view,setView] = useState(false)
  const {  fullMenuToggle, setFullMenuToggle ,setMenuToggle,showExpandedMenu,setShowExpandedMenu , menuToggle ,setShowToggler,setIframeUrl,collapsed} = props;
  const [menuData, setMenuData] = useState([]);
  const history = useHistory();
  const [hoveredMenuItem, setHoveredMenuItem] = useState(null);
  let lowerSubMenu = JSON.parse(localStorage.getItem("lowerSubMenu"));
  let navFontColor = globalStore.userData.CW360_V2_UI.appTheme.navBarFontColor;
  let headerColor = globalStore.userData.CW360_V2_UI.appTheme.navBarColor;
  let selectecMianMenuItem = JSON.parse(localStorage.getItem("selectecMianMenuItem"))

  useEffect(() => {   
    if (menuList) {
      try {
        menuList.sort((a, b) => (a.seqno != null ? parseInt(a.seqno) : Infinity) - (b.seqno != null ? parseInt(b.seqno) : Infinity));
        menuList.map((item, index) => {
          if (item.children && item.children.length > 0) {
            item.children.sort((a, b) => (a.seqno != null ? parseInt(a.seqno) : Infinity) - (b.seqno != null ? parseInt(b.seqno) : Infinity));
            item.children.map((subItem, subIndex) => {
              if (subItem.children && subItem.children.length > 0) {
                subItem.children.sort((a, b) => (a.seqno != null ? parseInt(a.seqno) : Infinity) - (b.seqno != null ? parseInt(b.seqno) : Infinity));
              }
              // item.children[subIndex] = subItem;
              return null;
            });
          }
          menuList[index] = item;
          return null;
        });
        setMenuData(menuList);
      } catch (error) {
        console.error("Failed to set menu data: ", JSON.stringify(error, null, 2));
      }
    }

  }, [menuList]);

  const visibleItems = menuData.slice(0,7); 
  const hiddenItems = menuData.slice(7); 

  const content = (<div
    // onMouseEnter={()=>{setShowExpandedMenu(!showExpandedMenu)}}
    // onMouseLeave={()=>{setShowExpandedMenu(!showExpandedMenu)}}
    style={{
      // position: "absolute",
      overflow:"hidden",
      bottom: 0,
      left: "calc(4.7em)",  // Adjust the value based on your list width and margin
      backgroundColor: "#fff",
      // border: "1px solid #ccc",
      boxShadow:'0px 0px 10px 1px rgba(0, 0, 0, 0.04)',
      zIndex: 1,  // Adjust the z-index as needed
      display: "flex",
      flexDirection: "column",
      width: "14em",  // Adjust the width as needed
    }}
    >
      <Row gutter={[8, 8]} style={{ padding: "1em" }}>
      {hiddenItems.map((menu) => (
        <Col
          span={12}
          onClick={() => handleListClick(menu.key, menu)}
          onMouseEnter={() => setHoveredMenuItem(menu.key)}
          onMouseLeave={() => setHoveredMenuItem(null)}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            paddingTop: "0.7em",paddingBottom:"0.7em",paddingLeft:"0.1em",paddingRight:"0.1em",
            backgroundColor: hoveredMenuItem === menu.key ? "#dadada" : "transparent",
          }}
          key={menu.key}
        >
          <Tooltip placement="top" title={menu.title}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {menu.icon ?
           <span
                  className={menu.icon}
                  style={{  color: selectecMianMenuItem === menu.key ? "#FEFEFE" : hoveredMenuItem === menu.key ? "#e4eafa":"#6277B8",fontSize:"1.2em",padding:"0 " }}
                >
                </span> :
            <Avatar size={30} style={{ border: "1px solid #6277B8", fontSize: "10px", background: "transparent",color:"#6277B8" }}>
              {menu.title.substring(0, 2).toUpperCase()}
            </Avatar>}
              <p
                style={{
                  fontSize: "9px",
                  marginBottom: 0,
                  fontWeight: 500,
                  fontFamily: "Inter",
                  textAlign: "center",
                  color: "#6277B8",
                }}
              >
                {menu.title}
              </p>
            </div>
          </Tooltip>
        </Col>
      ))}
    </Row>
    </div>)
  
  const selectMenuToNavigate = (data) => {
    setIframeUrl(null)
    localStorage.setItem("lowerSubMenuItem", data.key);
    if (data.type === "Report") {
      localStorage.setItem("windowType","Report")
      history.push(`/reports/report/${data.id}`);
    } else if (data.type === "Dashboard") {
      localStorage.setItem("windowType","Dashboard")
      history.push(`/analytics/dashboard/${data.id}`);
    } else if (data.type === "Generic") {
      history.push(`/window/list/${data.id}`);
    } else if (data.type === "Custom") {
      history.push(`/others/window/${data.id}`);
    } else if (data.type === "External" ) {
      if(data.navigation === "NewTab"){
        window.open(`${data.url}`)
      }else if (data.navigation === "EmbeddedView"){
        setIframeUrl(data.url)
      }else if (data.navigation === "popopWindow"){
        const popupUrl = data.url; 
      const screenWidth = window.screen.width;
      const screenHeight = window.screen.height;
      const popupWidth = 600; // Set the width of the popup window
      const popupHeight = 400; // Set the height of the popup window
      const leftPosition = (screenWidth - popupWidth) / 2;
      const topPosition = (screenHeight - popupHeight) / 2;
      // Define other options for the popup window
      const popupOptions = `
        width=${popupWidth},
        height=${popupHeight},
        top=${topPosition},
        left=${leftPosition},
        resizable=yes,
        scrollbars=yes,
        status=no,
        toolbar=no,
        menubar=no,
        location=no `;
      window.open(popupUrl, "popupWindow", popupOptions);
      }
    } else {
      message.warning("Not Available");
    } 
  };
 
  const handleListClick = (menuKey, menu) => {
    setShowExpandedMenu(false)
    menuData.map((menu, index) => {
      if (menu.key === menuKey) {
        localStorage.setItem("selectecMianMenuItem", JSON.stringify(menuKey))
        localStorage.setItem("subMenuData", JSON.stringify(menu.children))
        // setSelectedMainMenuItem(menu.children)
      }
    })
    navigateToFirstChild({ type: menu.children[0].type, id: menu.children[0].id, key: menu.children[0].key, children: menu.children[0].children})
  }

  const navigateToFirstChild = (data) => {
    console.log(data);
    if (!data.children || data.children.length === 0) {
      // If the current item has no children, navigate to its corresponding route
      localStorage.removeItem('lowerSubMenu');
      localStorage.setItem("subMenuItem", JSON.stringify(data.key));
      if (data.type === "Report") {
        history.push(`/reports/report/${data.id}`);
      } else if (data.type === "Dashboard") {
        localStorage.setItem("windowType", "Dashboard");
        history.push(`/analytics/dashboard/${data.id}`);
      } else if (data.type === "Generic") {
        localStorage.setItem("lowerSubMenuItem", data.key);
        history.push(`/window/list/${data.id}`);
      } else if (data.type === "Custom") {
        history.push(`/others/window/${data.id}`);
      }
    } else {
      // If the current item has children, navigate to the first child
      navigateToFirstChild(data.children[0]);
    }
  }
  

  const renderThumb = ({ style, ...props }) => {
    const thumbStyle = {
      backgroundColor: "#c1c1c1",
      borderRadius: "5px",
      width: "8px",
    };
    return <div style={{ ...style, ...thumbStyle }} {...props} />;
  };

  const renderView = ({ style, ...props }) => {
    const viewStyle = {
      color: "#00000",
    };
    return <div className="box" style={{ ...style, ...viewStyle }} {...props} />;
  };

  

  const fullToggle = () => {
    setFullMenuToggle((t) => !t);
    setTimeout(() => {
      setMenuToggle((t) => !t);
      setShowToggler(false);
    }, 0);
  }

  let lowerSubMenuItem = localStorage.getItem("lowerSubMenuItem")
  let sideMenuStyle = ""
  let rightMargin = ""
  if(menuToggle){
    sideMenuStyle = "0px"
    rightMargin="10px"
  }else{
    sideMenuStyle = "0px"
    rightMargin="0em"
  }

  return (
    <div className="" style={{marginLeft:sideMenuStyle,marginRight:rightMargin,fontFamily:'Inter'}}>
    <>
    <Sider
  collapsedWidth={collapsed ? 0 : 65}
  width={collapsed ? 0 : 65}
  className="third"
  trigger={null}
  collapsed={!collapsed}
  style={{
    transition: 'all 0.5s ease-in-out', // smoother transition
    transform: collapsed ? 'translateX(-100%)' : 'translateX(0)', // smooth transform
    height: "100vh"
  }}
>
  <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
    <List className="iconMenu" itemLayout="vertical" split={false} style={{ backgroundColor: headerColor?headerColor:"#0C173AE5", width: "auto", flex: "1 1 auto" }}>
    <List.Item style={{ cursor: "pointer" }} onClick={() => { console.log("home"); history.push("/myApps") }}>
  <span style={{ padding: "0 1.2em", display: "flex", justifyContent: "center" }}>
    <img src={sideIcon} alt="" />
  </span>
</List.Item>

      {visibleItems.map(menu => (
        <List.Item
          onMouseEnter={() => setHoveredMenuItem(menu.key)}
          onMouseLeave={() => setHoveredMenuItem(null)}
          onClick={() => handleListClick(menu.key, menu)}
          style={{
            height: "4em",
            cursor: "pointer",
            backgroundColor: selectecMianMenuItem === menu.key || hoveredMenuItem === menu.key ? "#334170" : "transparent",
            textAlign: "center",
            // borderRadius: "8px",
            display: "flex", // Add display: flex
            alignItems: "center", // Align items vertically
            justifyContent: "center", // Center items horizontally
          }}
          key={menu.key}
        >
          <Tooltip placement="right" title={menu.title}>
          {menu.icon ?
           <span
                  className={menu.icon}
                  style={{  color: selectecMianMenuItem === menu.key ? "#FEFEFE" : hoveredMenuItem === menu.key ? "#e4eafa":"#6277B8",fontSize:"1.2em",padding:"0 " }}
                >
                </span> :
            <Avatar size={30} style={{ border: "1px solid #6277B8", fontSize: "10px", background: "transparent" }}>
              {menu.title.substring(0, 2).toUpperCase()}
            </Avatar>}
            <p style={{ fontSize: "9px", marginBottom: 0, marginTop: "0.1em", lineHeight: "10px", fontWeight: 500, fontFamily: "Inter", textAlign: "center", color : navFontColor?navFontColor:"#6277B8"
              // color: selectecMianMenuItem === menu.key ? "#FEFEFE" : "#6277B8"
               }}>
              {menu.title}
            </p>
          </Tooltip>
        </List.Item>
      ))}
    </List>
    {hiddenItems.length > 0 && (
      <List className="iconMenu" itemLayout="vertical" split={false} style={{ backgroundColor: headerColor?headerColor:"#0C173AE5", width: "auto", flex: "0 0 auto" }}>
        <List.Item
          // onClick={toggleExpandedMenu}
          style={{ cursor: "pointer", margin: "0.5em 1px 0.5em 0em", backgroundColor: "transparent" }}
          key="ellipsis"
        >
          <Tooltip placement="right" title="More">
            <Popover content={content} placement="right">
              <span
                className="fa fa-ellipsis-h"
                style={{ color: "#6277B8", fontSize: "1.5em", padding: "0 0.9em" }}
              >

              </span>
            </Popover>
          </Tooltip>
        </List.Item>
      </List>
    )}
  </div>
</Sider>

       </>
       
    </div>
  );
};

export default SideNav;